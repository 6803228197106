html,
body {
  background-color: #fff9ef;
}

.container {
  padding-top: 12px;
  padding-bottom: 70px;
}

.navbar-dark .navbar-nav .nav-link {
  color: #ffffff;
}

.bg-taw {
  background-color: #43876e !important;
}

.btn-taw {
  color: #fff;
  background-color: #c7ab6e;
  border-color: #c7ab6e;
  min-width: 150px;
}
.btn-taw:hover {
  color: #f2dfb5;
}

.btn-success {
  color: #fff;
  background-color: #66cb7d;
  border-color: #66cb7d;
}

.btn-success:hover {
  color: #c1f2cc;
  background-color: #66cb7d;
  border-color: #66cb7d;
}

.btn-outline-light:hover {
  color: #ffffff;
  background-color: #da3535;
  border-color: #da3535;
}

.card {
  background-color: #fff6e7;
}

.card-header {
  padding: 0px;
}

.btn-link,
.btn-link:hover {
  color: #000;
}
